<template>
  <v-container id="dashboard-view" fluid tag="section" class="ma-0 pa-0">
    <BookingDialog :isOpen="dialog" @closeDialog="dialog = false" />
    <div style="display: flex; flex-wrap: wrap; margin-top: -40px">
      <v-col md="8">
        <v-tabs
          background-color="transparent"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
        >
          <v-tabs-slider color="#b5920d"></v-tabs-slider>
          <v-tab
            v-for="(item, i) in getSuburbs"
            :key="i"
            :href="'#tab-' + i"
            class="text--primary"
          >
            <v-menu :close-on-content-click="false" offset-y>
              <template v-slot:activator="{ on }">
                <div
                  v-on="on"
                  style="min-width: 200px; padding: 7px 0"
                  @click="suburbClicked(item)"
                >
                  {{ i + 1 + ". " + item.suburb + `  (${item.taxis.length})` }}
                </div>
              </template>
              <v-expansion-panels accordion style="max-width: 200px">
                <v-expansion-panel v-for="(taxi, i) in item.taxis" :key="i">
                  <v-expansion-panel-header style="font-size: 13px">
                    <div v-if="taxi.vehicleNo">
                      <strong>Rego:</strong>
                      {{ taxi.vehicleNo }}<br />
                      <strong v-if="taxi.ownerPhoneNumber">Phone:</strong>
                      {{
                        taxi.phoneNumber
                          ? taxi.phoneNumber
                          : taxi.ownerPhoneNumber
                      }}
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      style="font-size: 12px; line-height: 1; max-width: 100px"
                    >
                      <!-- <p v-if="taxi.vehicleNo">
                      <strong>Rego:</strong>
                      {{ taxi.vehicleNo }}
                    </p> -->
                      <p v-if="taxi.ownerFirstName">
                        <strong>Owner: </strong>
                        {{
                          (taxi.ownerFirstName ? taxi.ownerFirstName : "") +
                          " " +
                          (taxi.ownerMiddleName ? taxi.ownerMiddleName : "") +
                          " " +
                          (taxi.ownerLastName ? taxi.ownerLastName : "")
                        }}
                      </p>
                      <p v-if="taxi.phoneNumber">
                        <strong>Phone:</strong>
                        {{ taxi.ownerPhoneNumber }}
                      </p>
                      <p v-if="taxi.driverNo">
                        <strong>Driver#:</strong>
                        {{ taxi.driverNo }}
                      </p>
                      <p v-if="taxi.firstName">
                        <strong>Name: </strong>
                        {{
                          (taxi.firstName ? taxi.firstName : "") +
                          " " +
                          (taxi.middleName ? taxi.middleName : "") +
                          " " +
                          (taxi.lastName ? taxi.lastName : "")
                        }}
                      </p>
                      <!-- <p v-if="taxi.phoneNumber">
                      <strong>Phone: </strong>{{ taxi.phoneNumber }}
                    </p> -->
                      <p v-if="taxi.regoExpiry">
                        <strong>Rego Expiry: </strong
                        >{{ formatDate(taxi.regoExpiry) }}
                      </p>
                      <p
                        v-if="taxi.status"
                        :style="
                          ';' +
                          (taxi.status ? 'color:' + taxi.status.color : '')
                        "
                      >
                        <strong>Status: </strong
                        >{{ taxi.status ? taxi.status.text : "" }}
                      </p>
                      <p v-if="taxi.address" class="text-blinker">
                        <strong>Address: </strong>{{ taxi.address }}
                      </p>
                      <p v-if="taxi.name">
                        <strong>IMEI: </strong>{{ taxi.name }}
                      </p>
                      <p v-if="taxi.description">
                        <strong>SIM:</strong>
                        {{ taxi.description }}
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-menu>
          </v-tab>
        </v-tabs>
      </v-col>

      <v-autocomplete
        v-model="filterOption"
        :items="filterOptions"
        hide-details
        single-line
        label="Search records"
        style="max-width: 150px"
        class="mr-3"
      >
      </v-autocomplete>

      <v-autocomplete
        v-model="center"
        :search-input.sync="search"
        :items="centerPredictions"
        item-text="address"
        return-object
        append-icon="mdi-magnify"
        hide-details
        single-line
        label="Search records"
        style="max-width: 300px"
      ></v-autocomplete>
    </div>

    <div
      id="map"
      style="
        width: 99%;
        height: 86vh;
        border-radius: 15px;
        position: absolute;
        margin-top: -20px;
      "
    ></div>

    <v-btn color="red" dark absolute bottom right fab @click="dialog = true">
      <v-icon>mdi-taxi</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import moment from "moment";
import Vue from "vue";
import InfoWindowComponent from "./components/InfoWindow.vue";
import BookingDialog from "./components/BookingDialog.vue";
import { get, sync } from "vuex-pathify";

export default {
  name: "DashboardView",
  components: { BookingDialog },
  data: () => ({
    dialog: false,
    map: null,
    center: "",
    isInitial: true,
    markers: [],
    centerPredictions: [],
    search: undefined,
    searchTimer: "",
    filterOption: "Place",
    filterOptions: ["Place", "Vehicle no"],
    iconConfig: {
      labelOrigin: new google.maps.Point(42, 13),
      size: new google.maps.Size(48, 48),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(15, 15),
    },
  }),

  computed: {
    ...get("taxi", ["getTaxiFilters"]),
    ...sync("taxi", ["taxiFilters"]),
    allTaxis: sync("taxi/taxis"),

    googleMapStyles() {
      return this.$store.state.user.mapStyles;
    },
    getMapCenter() {
      return this.$store.state.authUser.mapCenter;
    },
    getSuburbs() {
      return this.$store.getters["taxi/getSuburbs"];
    },
    driverStatuses() {
      return this.$store.state.taxi.driverStatuses;
    },
    deviceStatuses() {
      return this.$store.state.taxi.deviceStatuses;
    },
  },

  watch: {
    search: {
      handler(val) {
        if (val) {
          clearTimeout(this.searchTimer);
          this.searchTimer = setTimeout(() => {
            if (this.filterOption === "Place") {
              this.searchLocation(val).then(
                (result) => {
                  this.centerPredictions = result;
                },
                () => {
                  this.centerPredictions = [];
                }
              );
            } else if (this.filterOption === "Vehicle no") {
              this.centerPredictions = this.taxiSearch(val);
              if (this.centerPredictions && this.centerPredictions.length > 0) {
                this.taxiFilters = [false, true, 1, 3];
              }
            }
          }, 1200);
        }
      },
    },
    center: {
      handler(val) {
        if (val) {
          if (this.filterOption === "Place") {
            if (val.fromGoogle) {
              this.getCoordinates(val.placeId)
                .then((response) => {
                  this.map.setCenter(response);
                })
                .catch(() => {});
            } else {
              this.map.setCenter({
                lat: val.latitude,
                lng: val.longitude,
              });
            }
          } else if (this.filterOption === "Vehicle no") {
            this.map.setCenter({
              lat: val.latitude,
              lng: val.longitude,
            });
          }
        }
      },
    },
    getTaxiFilters(val) {
      this.showFilteredTaxis(val);
    },
    allTaxis() {
      if (this.isInitial) {
        this.showFilteredTaxis(this.getTaxiFilters);
        this.isInitial = false;
      }
    },
  },

  created() {
    this.$locationHub.$on("location-changed", this.onLocationChanged);
    this.$locationHub.$on(
      "device-location-changed",
      this.onDeviceLocationChanged
    );
    this.$locationHub.$on("device-status-changed", this.onDeviceStatusChanged);
    this.$locationHub.$on("driver-status-changed", this.onDriverStatusChanged);
  },

  mounted() {
    this.initMap();
    this.showFilteredTaxis(this.getTaxiFilters);
  },

  beforeDestroy() {
    this.$locationHub.$off("location-changed", this.onLocationChanged);
    this.$locationHub.$off(
      "device-location-changed",
      this.onDeviceLocationChanged
    );
    this.$locationHub.$off("device-status-changed", this.onDeviceStatusChanged);
    this.$locationHub.$off("driver-status-changed", this.onDriverStatusChanged);
  },

  methods: {
    initMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: this.getMapCenter,
        zoom: 13,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        //styles: this.googleMapStyles,
        mapId: "b54202e65490cbc5",
      });
    },

    setMarkers(list) {
      this.markers.forEach((x) => {
        x.setVisible(false);
      });
      if (list != null) {
        if (list.length > 0) {
          list.forEach((data) => {
            this.setMarker(data);
          });
        }
      }
    },

    setMarker(item) {
      let markerIndex = this.markers.findIndex((x) => x.id === item.macAddress);

      if (
        item.latitude &&
        item.latitude !== 0 &&
        item.longitude &&
        item.longitude !== 0
      ) {
        let status;
        if (item.isDriver) {
          if (item.active) {
            status = this.getTaxiStatus(item.active);
          } else {
            status = this.getTaxiStatus(item.driverStatus);
          }
        } else {
          status = this.getTaxiStatus(item.active);
        }
        item.status = status;

        if (markerIndex > -1) {
          let position = {
            lat: item.latitude,
            lng: item.longitude,
          };
          this.markers[markerIndex].setPosition(position);

          if (this.markers[markerIndex].getIcon().url !== status.icon) {
            var url = status.icon;
            this.markers[markerIndex].setIcon(
              Object.assign({ url: status.icon }, this.iconConfig)
            );
          }

          this.markers[markerIndex].setVisible(true);
        } else {
          var marker = new google.maps.Marker({
            map: this.map,
            position: { lat: item.latitude, lng: item.longitude },
            label: {
              text: "" + item.vehicleNo,
              color: "black",
              fontSize: "12px",
              fontWeight: "bold",
            },
            icon: Object.assign({ url: status.icon }, this.iconConfig),
            id: item.macAddress,
          });

          this.setInfoWindow(item, marker);
          this.markers.push(marker);
        }
      } else {
        if (markerIndex > -1) {
          var status = {
            id: -1,
            text: "NoLocation",
            icon: this.$http.defaults.baseURL.replace(
              "/api/",
              "/Resources/Icons/red-taxi-24.png"
            ),
            color: "#e02727",
          };

          if (this.markers[markerIndex].getIcon().url !== status.icon) {
            this.markers[markerIndex].setIcon(
              Object.assign({ url: status.icon }, this.iconConfig)
            );
          }

          this.markers[markerIndex].setVisible(true);
        }
      }
    },

    setInfoWindow(item, marker) {
      var infoWindow = new google.maps.InfoWindow({
        // pixelOffset: new google.maps.Size(0, 15),
      });

      var InfoWindow = Vue.extend(InfoWindowComponent);
      var instance = new InfoWindow({
        propsData: {
          infoWindow: infoWindow,
          infoContent: item,
          isExtented: false,
        },
      });
      instance.$mount();

      var clicked = false;
      marker.addListener("mouseover", function () {
        if (!clicked) {
          instance._props.isExtented = false;
          infoWindow.setContent(instance.$el);
          infoWindow.open(this.map, marker);
        }
      });

      marker.addListener("mouseout", function () {
        if (!clicked) {
          infoWindow.close();
        }
      });

      marker.addListener("click", function () {
        clicked = true;
        instance._props.isExtented = true;
        infoWindow.setContent(instance.$el);
        infoWindow.open(this.map, marker);
      });

      google.maps.event.addListener(infoWindow, "closeclick", function () {
        clicked = false;
      });
    },

    onLocationChanged(location) {
      let driver;
      if (this.allTaxis && this.allTaxis.length > 0) {
        driver = this.allTaxis.find((x) => x.username === location.username);
      }
      if (driver && this.isStatusContains(driver.driverStatus)) {
        driver.latitude = location.latitude;
        driver.longitude = location.longitude;
        this.setMarker(driver);
      }
    },

    onDeviceLocationChanged(location) {
      let device;
      if (this.allTaxis && this.allTaxis.length > 0) {
        device = this.allTaxis.find(
          (x) => x.macAddress === location.macAddress
        );
      }
      if (device && this.isStatusContains(device.active)) {
        device.latitude = location.latitude;
        device.longitude = location.longitude;
        this.setMarker(device);
      }
    },

    onDeviceStatusChanged(status) {
      let device;
      if (this.allTaxis && this.allTaxis.length > 0) {
        device = this.allTaxis.find((x) => x.macAddress === status.macAddress);
      }
      if (device) {
        if (status && this.isStatusContains(status.active)) {
          device.active = status.active;
          this.setMarker(device);
        } else {
          this.deviceMarkerVisibility(device);
        }
      }
    },

    onDriverStatusChanged(status) {
      let driver;
      if (this.allTaxis && this.allTaxis.length > 0) {
        driver = this.allTaxis.find((x) => x.username === status.username);
      }
      if (driver) {
        if (status && this.isStatusContains(status.driverStatus)) {
          driver.driverStatus = status.driverStatus;
          this.setMarker(driver);
        } else {
          this.driverMarkerVisibility(status, false);
        }
      }
    },

    deviceMarkerVisibility(device, visible) {
      let marker = this.markers.find((x) => x.macAddress === device.macAddress);
      if (marker) {
        marker.setVisible(visible);
      }
    },

    driverMarkerVisibility(driver, visible) {
      let marker = this.markers.find((x) => x.macAddress === driver.macAddress);
      if (marker) {
        marker.setVisible(visible);
      }
    },

    suburbClicked(item) {
      if (item.taxis && item.taxis.length > 0) {
        let taxi = item.taxis[0];
        if (taxi && taxi.latitude != 0 && taxi.longitude != 0) {
          this.map.setCenter({
            lat: taxi.latitude,
            lng: taxi.longitude,
          });
        }
      }
    },

    async searchLocation(val) {
      try {
        let response = await this.$http.post("/BaseStation/SearchPlace", {
          searchValue: val,
          take: 5,
        });

        if (response.data.success) {
          if (response.data.data && response.data.data.length > 0) {
            return Promise.resolve(response.data.data);
          }
        }

        return this.searchGoogleLocation(val);
      } catch (error) {
        console.log(error);
        return this.searchGoogleLocation(val);
      }
    },

    async searchGoogleLocation(val) {
      return new Promise((resolve, reject) => {
        try {
          let service = new google.maps.places.AutocompleteService();
          service.getPlacePredictions(
            {
              input: val,
              componentRestrictions: { country: "au" },
            },
            (predictions, status) => {
              if (status !== "OK") {
                reject([]);
              }
              if (predictions && predictions.length > 0) {
                let result = predictions.map((res) => {
                  return {
                    fromGoogle: true,
                    placeId: res.place_id,
                    address: res.description,
                  };
                });
                resolve(result);
              } else {
                resolve([]);
              }
            }
          );
        } catch (error) {
          resolve([]);
        }
      });
    },

    async getCoordinates(placeId) {
      return new Promise((resolve, reject) => {
        try {
          let geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            {
              placeId: placeId,
            },
            (responses, status) => {
              if (status !== "OK") {
                reject(status);
              }
              if (responses && responses.length > 0) {
                let lat = responses[0].geometry.location.lat();
                let lng = responses[0].geometry.location.lng();
                resolve({ lat, lng });
              }
            }
          );
        } catch (error) {
          reject(error);
        }
      });
    },

    taxiSearch(search) {
      return this.allTaxis
        .filter(
          (x) =>
            x.vehicleNo.toLowerCase().includes(search.toLowerCase()) &&
            x.latitude !== 0 &&
            x.longitude !== 0
        )
        .map((x) => {
          return {
            placeId: x.vehicleNo,
            address: x.vehicleNo,
            latitude: x.latitude,
            longitude: x.longitude,
          };
        });
    },

    getTaxiStatus(id) {
      return this.$store.getters["taxi/getTaxiStatus"](id);
    },

    getTaxisByStatus(id) {
      return this.$store.getters["taxi/getTaxisByStatus"](id);
    },

    showFilteredTaxis(filters) {
      var taxis = [];
      filters.forEach((x) => {
        taxis = [...taxis, ...this.getTaxisByStatus(x)];
      });
      this.setMarkers(taxis);
    },

    isStatusContains(status) {
      return this.getTaxiFilters.includes(status);
    },

    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY");
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
.text-blinker {
  color: #f44336;
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>