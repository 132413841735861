<template>
  <div>
    <div style="display: flex">
      <div>
        <div><img src="../../assets/taxiDriver.png" /></div>
      </div>
      <div style="display: flex; flex-direction: column; margin-left: 10px">
        <strong style="font-size: 15px"
          >Rego: {{ infoContent.vehicleNo }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.ownerFirstName"
          >Owner:
          {{
            (infoContent.ownerFirstName ? infoContent.ownerFirstName : "") +
            " " +
            (infoContent.ownerMiddleName ? infoContent.ownerMiddleName : "") +
            " " +
            (infoContent.ownerLastName ? infoContent.ownerLastName : "")
          }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.ownerPhoneNumber"
          >Phone: {{ infoContent.ownerPhoneNumber }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.driverNo"
          >Driver#: {{ infoContent.driverNo }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.firstName"
          >Name:
          {{
            (infoContent.firstName ? infoContent.firstName : "") +
            " " +
            (infoContent.middleName ? infoContent.middleName : "") +
            " " +
            (infoContent.lastName ? infoContent.lastName : "")
          }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.phoneNumber"
          >Phone: {{ infoContent.phoneNumber }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.regoExpiry"
          >Rego Expiry: {{ formatDate(infoContent.regoExpiry) }}</strong
        >
        <strong
          v-if="infoContent.status"
          :style="
            'font-size: 15px;' +
            (infoContent.status ? 'color:' + infoContent.status.color : '')
          "
          >Status:
          {{ infoContent.status ? infoContent.status.text : "" }}</strong
        >
        <strong
          style="font-size: 14px"
          v-if="infoContent.lastActiveStatusChangedTime"
          >{{ infoContent.active ? "Active Since" : "In Active Since" }}:
          {{ formatDatetime(infoContent.lastActiveStatusChangedTime) }}</strong
        >
        <strong style="font-size: 14px" v-if="infoContent.lastConnected"
          >Connected at: {{ formatDatetime(infoContent.lastConnected) }}</strong
        >
        <strong style="font-size: 14px" v-if="infoContent.locationUpdatedTime"
          >Location updated at:
          {{ formatDatetime(infoContent.locationUpdatedTime) }}</strong
        >
        <p
          style="max-width: 250px; text-align: center; margin: 2px 0 5px 0"
          v-if="infoContent.address"
        >
          <strong class="text-blinker" style="font-size: 14px">{{
            infoContent.address
          }}</strong>
        </p>
        <strong style="font-size: 15px" v-if="infoContent.name"
          >IMEI: {{ infoContent.name }}</strong
        >
        <strong style="font-size: 15px" v-if="infoContent.description"
          >SIM: {{ infoContent.description }}</strong
        >
      </div>
    </div>
    <div
      style="display: flex; justify-content: center; align-items: center"
      v-if="isExtented"
    >
      <input
        type="text"
        class="message-input"
        v-model="message"
        v-on:keyup.enter="messageToDriver()"
        placeholder="Message"
      />
      <img
        src="../../assets/send.png"
        width="40"
        height="40"
        style="margin-left: 5px; cursor: pointer"
        alt=""
        @click="messageToDriver()"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import store from "../../store";

export default {
  name: "InfoWindow",
  props: ["infoWindow", "infoContent", "isExtented"],
  data: () => ({ message: "" }),

  created() {
    this.$locationHub.$on("location-changed", this.onLocationChanged);
    this.$locationHub.$on(
      "device-location-changed",
      this.onDeviceLocationChanged
    );
    this.$locationHub.$on("device-status-changed", this.onDeviceStatusChanged);
    this.$locationHub.$on("driver-status-changed", this.onDriverStatusChanged);
  },
  beforeDestroy() {
    this.$locationHub.$off("location-changed", this.onLocationChanged);
    this.$locationHub.$off(
      "device-location-changed",
      this.onDeviceLocationChanged
    );
    this.$locationHub.$off("device-status-changed", this.onDeviceStatusChanged);
    this.$locationHub.$off("driver-status-changed", this.onDriverStatusChanged);
  },
  methods: {
    onLocationChanged(location) {
      if (this.infoContent.firstName === location.username) {
        this.infoContent.address = location.address;
      }
    },
    onDeviceLocationChanged(location) {
      if (this.infoContent.macAddress === location.macAddress) {
        this.infoContent.address = location.address;
      }
    },
    onDeviceStatusChanged(status) {
      if (this.infoContent.macAddress === status.macAddress) {
        let _status = this.getTaxiStatus(status.active);
        this.infoContent.status = _status;
      }
    },
    onDriverStatusChanged(status) {
      if (this.infoContent.firstName === status.username) {
        let _status = this.getTaxiStatus(status.driverStatus);
        this.infoContent.address = _status;
      }
    },
    messageToDriver() {
      if (this.infoContent.isDriver && !this.infoContent.active) {
        console.log(
          "message To Driver",
          this.infoContent.firstName,
          this.message
        );
        this.sendMessageToUser(this.infoContent.firstName, this.message);
      } else {
        store
          .dispatch("message/sendMessageToDevice", {
            macAddress: this.infoContent.macAddress,
            message: this.message,
          })
          .then((response) => {
            if (!response.data.success) {
              this.$toast.error("Something went wrong.", "Error", {
                position: "topRight",
              });
            }
            this.message = "";
          })
          .catch((error) => {
            if (error.data === "No connection") {
              this.$toast.error("The device is offline.", "Error", {
                position: "topRight",
              });
            } else {
              this.$toast.error("Something went wrong.", "Error", {
                position: "topRight",
              });
            }
            this.message = "";
          });
      }
      this.infoWindow.close();
    },
    sendMessageToUser(username, message) {
      store.dispatch("message/sendMessageToDriver", {
        username,
        message,
        context: this,
      });
      if (this.infoContent.mobile) {
        let phoneNumber;
        if (this.infoContent.mobile.includes("+61")) {
          phoneNumber = this.infoContent.mobile;
        } else {
          phoneNumber = "+61" + this.infoContent.mobile.trim().substring(1);
        }
        // this.$http
        //   .post("/SMS", { message: message, to: phoneNumber })
        //   .then(() => {})
        //   .catch((err) => {
        //     console.log("sms:", err);
        //   });
      }
    },
    formatDate(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("MMMM Do YYYY");
      } else {
        return "";
      }
    },
    formatDatetime(dateTime) {
      if (dateTime) {
        return moment.utc(dateTime).local().format("DD.MM.YYYY, h:mm:ss a");
      } else {
        return "";
      }
    },
    getTaxiStatus(active) {
      return store.getters["taxi/getTaxiStatus"](active);
    },
  },
};
</script>

<style scoped>
.text-blinker {
  color: #f44336;
  animation: blinker 1.5s linear infinite;
}
.message-input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  margin: 5px 0;
  display: inline-block;
  border: 1px solid #b8e1ff;
  border-radius: 4px;
  box-sizing: border-box;
}
.message-input:focus {
  outline: none;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>